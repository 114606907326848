import React from "react";
import "../../Styles/ProgramsUs.css";
import Slider from "react-slick";
import program_img1 from "../../Assets/programs_img1.png";
import program_img2 from "../../Assets/programs_img2.png";
import program_img3 from "../../Assets/programs_img3.png";
import program_img4 from "../../Assets/programs_img4.png";
function ProgramsUS() {
  function SampleNextArrow(props) {
    const {
      className = "slick_button",
      style,
      onClick,
      color = "black",
    } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "White",
          height: "60px",
          width: "60px",
          borderRadius: "60px",
          color: color,
          border: "1px solid #d6d6d6",
        }}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M17.5624 16.0009L10.9626 9.40124L12.8483 7.51562L21.3336 16.0009L12.8483 24.4861L10.9626 22.6005L17.5624 16.0009Z"
            fill="black"
          />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick, color = "black" } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "White",
          height: "60px",
          width: "60px",
          borderRadius: "60px",
          color: color,
          border: "1px solid #d6d6d6",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <svg
          style={{ rotate: "180deg" }}
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M17.5624 16.0009L10.9626 9.40124L12.8483 7.51562L21.3336 16.0009L12.8483 24.4861L10.9626 22.6005L17.5624 16.0009Z"
            fill="black"
          />
        </svg>
      </div>
    );
  }
  const settings = {
    // autoplay: true,
    // infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container">
      <div id="us_programs_text">Programs</div>
      <div id="us_programs_subtext">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor <br />
        incididunt ut labore et dolore magna aliqua. 
      </div>
      
      <Slider {...settings} className="row">
        <div className="col-md-4">
          <div id="us_single_program_bg">
            <div id="us_program_inner_bg">
              <div id="us_batch_date">Next Batch Starts: 20-Sep-2024</div>
              <div id="us_pro_img">
                <img src={program_img1} alt="p1" />
              </div>
            </div>

            <div id="us_pro_name_bg">
              <div id="us_pro_name">Data Science</div>
              <div id="us_pro_about">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit,
              </div>
              <button className="btn" id="us_pro_button">
                Apply Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div id="us_single_program_bg">
            <div id="us_program_inner_bg">
              <div id="us_batch_date">Next Batch Starts: 20-Sep-2024</div>
              <div id="us_pro_img">
                <img src={program_img2} alt="p1" />
              </div>
            </div>

            <div id="us_pro_name_bg">
              <div id="us_pro_name">Full Stack Development</div>
              <div id="us_pro_about">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit,
              </div>
              <button className="btn" id="us_pro_button">
                Apply Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div id="us_single_program_bg">
            <div id="us_program_inner_bg">
              <div id="us_batch_date">Next Batch Starts: 20-Sep-2024</div>
              <div id="us_pro_img">
                <img src={program_img3} alt="p1" />
              </div>
            </div>

            <div id="us_pro_name_bg">
              <div id="us_pro_name">Cloud Engineering</div>
              <div id="us_pro_about">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit,
              </div>
              <button className="btn" id="us_pro_button">
                Apply Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div id="us_single_program_bg">
            <div id="us_program_inner_bg">
              <div id="us_batch_date">Next Batch Starts: 20-Sep-2024</div>
              <div id="us_pro_img">
                <img src={program_img4} alt="p1" />
              </div>
            </div>

            <div id="us_pro_name_bg">
              <div id="us_pro_name">DevOps</div>
              <div id="us_pro_about">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit,
              </div>
              <button className="btn" id="us_pro_button">
                Apply Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div id="us_single_program_bg">
            <div id="us_program_inner_bg">
              <div id="us_batch_date">Next Batch Starts: 20-Sep-2024</div>
              <div id="us_pro_img">
                <img src={program_img1} alt="p1" />
              </div>
            </div>

            <div id="us_pro_name_bg">
              <div id="us_pro_name">Data Science</div>
              <div id="us_pro_about">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit,
              </div>
              <button className="btn" id="us_pro_button">
                Apply Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div id="us_single_program_bg">
            <div id="us_program_inner_bg">
              <div id="us_batch_date">Next Batch Starts: 20-Sep-2024</div>
              <div id="us_pro_img">
                <img src={program_img2} alt="p1" />
              </div>
            </div>

            <div id="us_pro_name_bg">
              <div id="us_pro_name">Data Science</div>
              <div id="us_pro_about">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit,
              </div>
              <button className="btn" id="us_pro_button">
                Apply Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div id="us_single_program_bg">
            <div id="us_program_inner_bg">
              <div id="us_batch_date">Next Batch Starts: 20-Sep-2024</div>
              <div id="us_pro_img">
                <img src={program_img3} alt="p1" />
              </div>
            </div>

            <div id="us_pro_name_bg">
              <div id="us_pro_name">Data Science</div>
              <div id="us_pro_about">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit,
              </div>
              <button className="btn" id="us_pro_button">
                Apply Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div id="us_single_program_bg">
            <div id="us_program_inner_bg">
              <div id="us_batch_date">Next Batch Starts: 20-Sep-2024</div>
              <div id="us_pro_img">
                <img src={program_img4} alt="p1" />
              </div>
            </div>

            <div id="us_pro_name_bg">
              <div id="us_pro_name">Data Science</div>
              <div id="us_pro_about">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit,
              </div>
              <button className="btn" id="us_pro_button">
                Apply Now
              </button>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default ProgramsUS;
