import React from "react";
import "../../Styles/OurExpertiseUS.css";
import our_expertise_vi1 from "../../Assets/our_expertise_vi1.mp4";
import our_expertise_vi2 from "../../Assets/our_expertise_vi2.mp4";
import our_expertise_vi3 from "../../Assets/our_expertise_vi3.mp4";
import experise_tick from "../../Assets/expertise_tick.png";

function OurExpertiseUS() {
  return (
    <div id="our_expertise_us_bg">
      <div className="container">
        <div id="our_expertise_text_us">Where Our Expertise Lies</div>
        <div id="our_expertise_subtext_us">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor <br />
          incididunt ut labore et dolore magna aliqua. 
        </div>
        <div className="row w-100">
          <div className="col-md-4">
            <div id="our_expertise_1">
              <video src={our_expertise_vi1} autoPlay loop width="100%" />
              <div id="our_expertise_us_bg1">
                Mentoring & Live Project
                <div id="expertise_points_bg">
                  <div
                    className="d-flex align-items-center "
                    id="expertise_single_points_bg"
                  >
                    <img src={experise_tick} alt="" />
                    <div>Mentored by Top Experts</div>
                  </div>
                  <div
                    className="d-flex align-items-center "
                    id="expertise_single_points_bg"
                  >
                    <img src={experise_tick} alt="" />
                    <div>Work in 2-3 Live Projects</div>
                  </div>
                  <div
                    className="d-flex align-items-center "
                    id="expertise_single_points_bg"
                  >
                    <img src={experise_tick} alt="" />
                    <div>Lorem ipsum dolor sit amet</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="our_expertise_1">
              <video src={our_expertise_vi2} autoPlay loop width="100%" />
              <div id="our_expertise_us_bg1">
                Interview Preparation
                <div id="expertise_points_bg">
                  <div
                    className="d-flex align-items-center "
                    id="expertise_single_points_bg"
                  >
                    <img src={experise_tick} alt="" />
                    <div>Mock Interview</div>
                  </div>
                  <div
                    className="d-flex align-items-center "
                    id="expertise_single_points_bg"
                  >
                    <img src={experise_tick} alt="" />
                    <div>Resume Building</div>
                  </div>
                  <div
                    className="d-flex align-items-center "
                    id="expertise_single_points_bg"
                  >
                    <img src={experise_tick} alt="" />
                    <div>Soft skill Training</div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="col-md-4">
            <div id="our_expertise_1">
              <video src={our_expertise_vi3} autoPlay loop width="100%" />
              <div id="our_expertise_us_bg1">
                Job Placement
                <div id="expertise_points_bg">
                  <div
                    className="d-flex align-items-center "
                    id="expertise_single_points_bg"
                  >
                    <img src={experise_tick} alt="" />
                    <div>Lorem ipsum dolor sit amet</div>
                  </div>
                  <div
                    className="d-flex align-items-center "
                    id="expertise_single_points_bg"
                  >
                    <img src={experise_tick} alt="" />
                    <div>Lorem ipsum dolor sit amet</div>
                  </div>
                  <div
                    className="d-flex align-items-center "
                    id="expertise_single_points_bg"
                  >
                    <img src={experise_tick} alt="" />
                    <div>Lorem ipsum dolor sit amet</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OurExpertiseUS;
