import React from "react";
import "../../Styles/AdvisorsUS.css";
import Marquee from "react-fast-marquee";

function AdvisorsUS() {
  return (
    <div id="us_advisors_bg">
      <div id="us_advisors_heading">Meet Our World-Class Mentors</div>
      <div id="us_advisors_subtext">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor
        <br /> incididunt ut labore et dolore magna aliqua. 
      </div>
      <div>
        {/* <Marquee
          id="us_advisors_img_row"
          gradient={false}
          style={{ marginTop: "20px", background: "none", zIndex: "0" , width:}}
        >
          <div id="us_advisors_img_bg">
            <div id="us_advisor_name">James</div>
            <div id="us_advisor_role">Full Stack Developer</div>
            <div
              id="us_ms_bg"
              className="position-absolute top-100 start-50 translate-middle"
            >
              Microsoft
            </div>
          </div>
          <div id="us_advisors_img_bg">
            <div id="us_advisor_name">James</div>
            <div id="us_advisor_role">Full Stack Developer</div>
            <div
              id="us_ms_bg"
              className="position-absolute top-100 start-50 translate-middle"
            >
              Microsoft
            </div>
          </div>
          <div id="us_advisors_img_bg">
            <div id="us_advisor_name">James</div>
            <div id="us_advisor_role">Full Stack Developer</div>
            <div
              id="us_ms_bg"
              className="position-absolute top-100 start-50 translate-middle"
            >
              Microsoft
            </div>
          </div>
          <div id="us_advisors_img_bg">
            <div id="us_advisor_name">James</div>
            <div id="us_advisor_role">Full Stack Developer</div>
            <div
              id="us_ms_bg"
              className="position-absolute top-100 start-50 translate-middle"
            >
              Microsoft
            </div>
          </div>
          <div id="us_advisors_img_bg">
            <div id="us_advisor_name">James</div>
            <div id="us_advisor_role">Full Stack Developer</div>
            <div
              id="us_ms_bg"
              className="position-absolute top-100 start-50 translate-middle"
            >
              Microsoft
            </div>
          </div>
          <div id="us_advisors_img_bg">
            <div id="us_advisor_name">James</div>
            <div id="us_advisor_role">Full Stack Developer</div>
            <div
              id="us_ms_bg"
              className="position-absolute top-100 start-50 translate-middle"
            >
              Microsoft
            </div>
          </div>
        </Marquee> */}
      </div>
    </div>
  );
}
export default AdvisorsUS;
