import React from "react";
import "../../Styles/HeroSectionUs.css";
import it_header_img from "../../Assets/it_header_img.png";
import us_google from "../../Assets/us_google.png";
import us_microsoft from "../../Assets/us_microsoft.png";
import us_capge from "../../Assets/us_capge.png";
import us_fb from "../../Assets/us_fb.png";
import us_zoho from "../../Assets/us_linkedin.png";
import us_netflix from "../../Assets/us_netfilx.png";
import us_adobe from "../../Assets/us_adobe.png";
import us_hero_left_img from "../../Assets/us_hero_left_img.png";
import us_hero_right_img from "../../Assets/us_hero_right_img.png";
import HeaderUS from "./HeaderUS";
function HeroSectionUS() {
  return (
    <div id="us_hero_bg">
      <HeaderUS />
      <div className="container">
        <div id="us_hero_contents">
          <div id="us_it_nerds_bg">
            <div>
              <img src={it_header_img} alt="" />
            </div>
            <div>Creating IT Nerds</div>
          </div>
          <div className="d-flex position-relative" id="us_hero_imgs">
            <div id="us_hero_left_img">
              <img src={us_hero_left_img} alt=""  />
            </div>
            <div id="us_hero_text">The Ultimate Career Accelerator</div>
            <div>
              <img src={us_hero_right_img} alt="" />
            </div>
          </div>
          <div id="us_hero_subtext">
            Achieve your career aspirations with our comprehensive <br />
            training and job placement services
          </div>
          <div id="us_hero_button_bg">
            <button className="btn" id="us_program_btn">
              View Programs
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 19.1638L18.2071 12.9567L16.7929 11.5425L12 16.3354L7.20707 11.5425L5.79285 12.9567L12 19.1638ZM12 13.5139L18.2071 7.30679L16.7929 5.89258L12 10.6855L7.20707 5.89258L5.79285 7.30679L12 13.5139Z"
                    fill="#081730"
                  />
                </svg>
              </span>
            </button>
            <button className="btn" id="us_register_btn">
              Register Now
            </button>
          </div>
          <div id="us_hiring_partners_text">Hiring Partners</div>
          <div id="us_hiring_outer_bg">
            <div id="us_hiring_partners">
              <div>
                <img src={us_google} alt="" width="100%" />
              </div>
              <div>
                <img src={us_microsoft} alt="" width="100%" />
              </div>
              <div>
                <img src={us_adobe} alt="" width="100%" />
              </div>
              <div>
                <img src={us_capge} alt="" width="100%" />
              </div>
              <div>
                <img src={us_fb} alt="" width="100%" />
              </div>
              <div>
                <img src={us_zoho} alt="" width="100%" />
              </div>
              <div>
                <img src={us_netflix} alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSectionUS;
