import React from "react";
import us_logo from "../../Assets/us_logo.png";
import nyinst_logo from "../../Assets/ny_us_header_logo.png";
import "../../Styles/HeaderUS.css";
function HeaderUS() {
  return (
    <div>
      <div id="us_header_bg">
        <div className="container d-flex align-items-center justify-content-between">
          <div id="us_logo_bg">
            <div id="us_logo">
              <img src={us_logo} alt="us_logo" width="100%" />
            </div>
            <div id="us_ny_logo">
              <img src={nyinst_logo} alt="nyinst_logo" width="100%" />
              <div id="us_header_text">
                New York Institute Of
                <br />
                <span className="ps-4">Science & Technology</span>
              </div>
            </div>
          </div>
          <div id="us_header_menu">
            <div>Programs</div>
            <div>About us</div>
            <div>About us</div>
            <div>Contact</div>
          </div>
          <button id="us_reg_button" className="btn">Register Now</button>
        </div>
      </div>
    </div>
  );
}
export default HeaderUS;
