import React, { useRef } from "react";
import "../Styles/OurExpertise.css";

import placement_cell from "../Assets/placement_img.svg";
// import industry_img from "../Assets/industry_img.svg";
// import mock_img from "../Assets/mock_img.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import guided_programs from "../Assets/guided_program.png";
import guided_program from "../Assets/guided_program.png";
import industry_readiness from "../Assets/industry_img.png";
import mock_interview from "../Assets/mock_interview.png";
import placement from "../Assets/placement.png";

import styled from "styled-components";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Sticky2Styled = styled.div`
  overflow: hidden;

  .section {
    height: 70vh;
    background: linear-gradient(160deg, #f47c66 0%, #e51906 100%);
  }

  .sticky,
  .sticky2 {
    height: 90vh;

    width: 100%;

    & .animation,
    .animation2 {
      width: 100%;
      height: 100%;
      position: absolute;

      & svg {
        opacity: 1;
        position: absolute;
        width: 300px;
        height: 300px;
      }
    }

    .heading {
      position: absolute;
      height: 100%;
      width: 100%;

      h2 {
        font-size: 40px;
        position: absolute;
        bottom: 10%;
        left: 10%;
        margin: 0;
      }
    }
  }
`;

function OurExpertise() {
  const sliderRef = useRef();
  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  var settingss = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 3,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    // pauseOnHover:false
  };
  var mobileSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    // centerMode:true,
    // responsive: [
    //   {
    //     breakpoint: 1301,
    //     settings: {
    //       slidesToShow: 4,
    //       slidesToScroll: 4,
    //       variableWidth: true,
    //       centerMode: true
    //       },
    //     },
    //     {
    //       breakpoint: 641,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         initialSlide: 1,
    //         variableWidth: true,

    //       },
    //    },
    // ],
  };
  return (
    <div>
      <div id="our_expertise_bg" className="d-lg-block d-none">
        <div
          className="d-md-block  d-none"
          align="center"
          id="our_expertise_topic"
          style={{
            marginTop: "6.25rem",
            fontSize: "1.25rem",
            fontWeight: "500",
            lineHeight: "1.75rem",
            fontStyle: "normal",
            fontFamily: "Plus Jakarta Sans",
            color: "#79818D",
          }}
        >
          Unleashing
          <div
            id="our_expert_text"
            style={{
              marginTop: "0.75rem",
              fontSize: "2.75rem",
              fontWeight: "800",
              lineHeight: "3.5rem",
              fontStyle: "normal",
              fontFamily: "Plus Jakarta Sans",
              color: "#222F43",
            }}
          >
            Our Expertise
          </div>
        </div>

        <div
          className="ny_in"
          style={{ height: "max-content", marginTop: "3.25rem" }}
        >
          <Slider
            {...settingss}
            className="d-flex overflow-hidden w-100 "
            id="expertises_bg"
          >
            <div>
              <img src={guided_program} alt="" width="100%" />
            </div>
            <div>
              <img src={industry_readiness} alt="" width="100%" />
            </div>
            <div>
              <img src={mock_interview} alt="" width="100%" />
            </div>
            <div>
              <img src={placement} alt="" width="100%" />
            </div>
            {/* <div id="guide_expertise_bg" className="position-relative">
              <div id="guided_expertise_text">Guided Program</div>
              <div id="guide_program_content">
                We have a dedicated tech team to guide you throughout the
                journey
              </div>
              <div id="guide_points_bg" className="first_point_bg">
                <li>1-1 doubt clearance</li>
              </div>
              <div id="guide_points_bg" className="second_point_bg">
                <li>Work in 2-3 live project</li>
              </div>
              <div id="guide_points_bg" className="third_point_bg">
                <li>Get experience letter along with certificate</li>
              </div>
              <div className="position-absolute bottom-0">
                <img
                  src={guided_program}
                  alt=""
                  width="100%"
                  style={{ marginTop: "2.8rem" }}
                />
              </div>
            </div>
            <div id="guide_expertise_bg" className="position-relative">
              <div id="guided_expertise_text">Guided Program</div>
              <div id="guide_program_content">
                We have a dedicated tech team to guide you throughout the
                journey
              </div>
              <div id="guide_points_bg" className="first_point_bg">
                <li>1-1 doubt clearance</li>
              </div>
              <div id="guide_points_bg" className="second_point_bg">
                <li>Work in 2-3 live project</li>
              </div>
              <div id="guide_points_bg" className="third_point_bg">
                <li>Practice test and assignments</li>
              </div>
              <div className="position-absolute bottom-0">
                <img
                  src={guided_program}
                  alt=""
                  width="100%"
                  style={{ marginTop: "2.8rem" }}
                />
              </div>
            </div>
            <div id="guide_expertise_bg" className="position-relative">
              <div id="guided_expertise_text">Guided Program</div>
              <div id="guide_program_content">
                We have a dedicated tech team to guide you throughout the
                journey
              </div>
              <div id="guide_points_bg" className="first_point_bg">
                <li>1-1 doubt clearance</li>
              </div>
              <div id="guide_points_bg" className="second_point_bg">
                <li>Work in 2-3 live project</li>
              </div>
              <div id="guide_points_bg" className="third_point_bg">
                <li>Optimizing accounts on job portals</li>
              </div>
              <div className="position-absolute bottom-0">
                <img
                  src={guided_program}
                  alt=""
                  width="100%"
                  style={{ marginTop: "2.8rem" }}
                />
              </div>
            </div>
            <div id="guide_expertise_bg" className="position-relative">
              <div id="guided_expertise_text">Guided Program</div>
              <div id="guide_program_content">
                We have a dedicated tech team to guide you throughout the
                journey
              </div>
              <div id="guide_points_bg" className="first_point_bg">
                <li>1-1 doubt clearance</li>
              </div>
              <div id="guide_points_bg" className="second_point_bg">
                <li>Work in 2-3 live project</li>
              </div>
              <div id="guide_points_bg" className="third_point_bg">
                <li>Guaranteed average salary 5LPA</li>
              </div>
              <div className="position-absolute bottom-0">
                <img
                  src={guided_program}
                  alt=""
                  width="100%"
                  style={{ marginTop: "2.8rem" }}
                />
              </div>
            </div> */}
            <div></div>

            {/* <div id="guide_expertise_bg">
              <div id="guided_expertise_text">Guided Program</div>
              <div id="guide_program_content">
                We have a dedicated tech team to guide you throughout the
                journey
              </div>
              <div id="guide_points_bg" className="first_point_bg">
                <li>1-1 doubt clearance</li>
              </div>
              <div id="guide_points_bg" className="second_point_bg">
                <li>Work in 2-3 live project</li>
              </div>
              <div id="guide_points_bg" className="third_point_bg">
                <li>Work in 2-3 live project</li>
              </div>
              <div>
                <img
                  src={guided_program}
                  alt=""
                  width="100%"
                  style={{ marginTop: "2.8rem" }}
                />
              </div>
            </div>
            <div id="guide_expertise_bg">
              <div id="guided_expertise_text">Guided Program</div>
              <div id="guide_program_content">
                We have a dedicated tech team to guide you throughout the
                journey
              </div>
              <div id="guide_points_bg" className="first_point_bg">
                <li>1-1 doubt clearance</li>
              </div>
              <div id="guide_points_bg" className="second_point_bg">
                <li>Work in 2-3 live project</li>
              </div>
              <div id="guide_points_bg" className="third_point_bg">
                <li>Work in 2-3 live project</li>
              </div>
              <div>
                <img
                  src={guided_program}
                  alt=""
                  width="100%"
                  style={{ marginTop: "2.8rem" }}
                />
              </div>
            </div>
            <div id="guide_expertise_bg">
              <div id="guided_expertise_text">Guided Program</div>
              <div id="guide_program_content">
                We have a dedicated tech team to guide you throughout the
                journey
              </div>
              <div id="guide_points_bg" className="first_point_bg">
                <li>1-1 doubt clearance</li>
              </div>
              <div id="guide_points_bg" className="second_point_bg">
                <li>Work in 2-3 live project</li>
              </div>
              <div id="guide_points_bg" className="third_point_bg">
                <li>Work in 2-3 live project</li>
              </div>
              <div>
                <img
                  src={guided_program}
                  alt=""
                  width="100%"
                  style={{ marginTop: "2.8rem" }}
                />
              </div>
            </div> */}
            {/* <div id="guide_expertise_bg">
              <div id="guided_expertise_text">Guided Program</div>
              <div id="guide_program_content">
                We have a dedicated tech team to guide you throughout the
                journey
              </div>
              <div id="guide_points_bg" className="first_point_bg">
                <li>1-1 doubt clearance</li>
              </div>
              <div id="guide_points_bg" className="second_point_bg">
                <li>Work in 2-3 live project</li>
              </div>
              <div id="guide_points_bg" className="third_point_bg">
                <li>Work in 2-3 live project</li>
              </div>
              <div>
                <img
                  src={guided_program}
                  alt=""
                  width="100%"
                  style={{ marginTop: "2.8rem" }}
                />
              </div>
            </div> */}
          </Slider>
        </div>
      </div>
      {/* for Mobiles */}
      <div className="d-block d-lg-none container-fluid">
        <div id="mobile_our_expertise_bg">
          <div
            className="d-md-none d-block"
            align="center"
            id="mobile_our_expertise_topic"
            style={{
              marginTop: "0.5rem",
              fontSize: "1rem",
              fontWeight: "500",
              lineHeight: "1.5rem",
              color: "#79818D",
              fontFamily: "Plus Jakarta Sans",
            }}
          >
            Unleashing
            <div
              id="mobile_our_expert_text"
              style={{
                marginTop: "0.5rem",
                fontSize: "1.5rem",
                fontWeight: "800",
                lineHeight: "2rem",
                color: "#222F43",
                fontFamily: "Plus Jakarta Sans",
              }}
            >
              Our Expertise
            </div>
          </div>
          <div className="ny_in">
            {" "}
            <Slider {...mobileSettings} className="" id="mobile_expertise_bg">
              <div>
                <img src={guided_program} alt="" width="100%" />
              </div>
              <div>
                <img src={industry_readiness} alt="" width="100%" />
              </div>
              <div>
                <img src={mock_interview} alt="" width="100%" />
              </div>
              <div>
                <img src={placement} alt="" width="100%" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurExpertise;
