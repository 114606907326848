import React from "react";
import "../../Styles/OurJourneyUS.css";
import Slider from "react-slick";

function OurJourneyUS() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    autoplay: true,
    
  };
  return (
    <div className="">
       <Slider {...settings} className="row w-100 p-0 m-0">
        <div className="col-md-4 p-0 m-0">
          <div id="us_single_program_bg">
            <div id="us_program_inner_bg">
              <div id="us_batch_date">Next Batch Starts: 20-Sep-2024</div>
              <div id="us_pro_img">
                {/* <img src={program_img1} alt="p1" /> */}
              </div>
            </div>

            <div id="us_pro_name_bg">
              <div id="us_pro_name">Data Science</div>
              <div id="us_pro_about">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit,
              </div>
              <button className="btn" id="us_pro_button">
                Apply Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4 p-0 m-0">
          <div id="us_single_program_bg">
            <div id="us_program_inner_bg">
              <div id="us_batch_date">Next Batch Starts: 20-Sep-2024</div>
              <div id="us_pro_img">
                {/* <img src={program_img2} alt="p1" /> */}
              </div>
            </div>

            <div id="us_pro_name_bg">
              <div id="us_pro_name">Full Stack Development</div>
              <div id="us_pro_about">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit,
              </div>
              <button className="btn" id="us_pro_button">
                Apply Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4 p-0 m-0">
          <div id="us_single_program_bg">
            <div id="us_program_inner_bg">
              <div id="us_batch_date">Next Batch Starts: 20-Sep-2024</div>
              <div id="us_pro_img">
                {/* <img src={program_img3} alt="p1" /> */}
              </div>
            </div>

            <div id="us_pro_name_bg">
              <div id="us_pro_name">Cloud Engineering</div>
              <div id="us_pro_about">
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit,
              </div>
              <button className="btn" id="us_pro_button">
                Apply Now
              </button>
            </div>
          </div>
        </div>
       
     
      </Slider>
    </div>
  );
}
export default OurJourneyUS;
