import React from "react";
import "../../Styles/JourneyUS.css";
import journer_img_us from "../../Assets/journey_img_us.png";
function JourneyUS() {
  return (
    <div>
      <div
        id="us_journey_bg"
        className="d-flex align-items-center justify-content-center"
      >
        <div className="container">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-md-6">
              <div>
                <div id="us_journey_title">Your Journey at NYINST</div>
                <div id="us_journey_subtitle">
                  Transformational ride, filled with learning, growth,
                  <br /> and invaluable connections
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                id="journey_img_us_bg"
                className="d-flex align-items-center justify-content-center"
              >
                <img src={journer_img_us} alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div id="us_our_journey_text">Our Incredible Journey</div>
        <div id="us_our_journey_subtext">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor <br />
          incididunt ut labore et dolore magna aliqua. 
        </div>
        <div id="us_about_journey" className="">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-md-4">
              <div id="us_about_jou_bg">
                <div id="us_about_journey1">200+</div>
                <div id="us_about_journey_subtext">MOU Signed</div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div id="us_about_jou_bg">
                <div id="us_about_journey1">1800+</div>
                <div id="us_about_journey_subtext">Clients</div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div id="us_about_jou_bg">
                <div id="us_about_journey1">5</div>
                <div id="us_about_journey_subtext">Locations</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default JourneyUS;
