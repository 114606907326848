import React from "react";
import "../../Styles/ContactUS.css";
import us_phone_icon from "../../Assets/us_phone_icon.png";
import us_email_icon from "../../Assets/us_email_icon.png";
import us_location_icon from "../../Assets/us_location.png";
function ContactUS() {
  return (
    <div className="container">
      <div id="us_contact_bg">
        <div className="row">
          <div className="col-md-6">
            <div>
              <div id="us_contact_text">Contact Us</div>
              <div id="us_contact_subtext">
                Reach out for any inquiries, support, or information about our
                programs, and let’s start a conversation
              </div>
              <div id="us_contact_details_bg">
                <div id="us_contact_img_bg">
                  <img src={us_email_icon} alt="" />
                </div>
                <div>
                  <div>Email</div>
                  <div>info@nyinst.com</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6"></div>
        </div>
      </div>
    </div>
  );
}

export default ContactUS;
