import React from "react";
import HeroSectionUS from "./HeroSectionUs";
import ProgramsUS from "./Programs";
import RoadMapUS from "./RoadMapUS";
import AdvisorsUS from "./AdvisorsUs";
import PartUS from "./PartUs";
import JourneyUS from "./JourneyUS";
import OurJourneyUS from "./OurJourneyUS";
import OurExpertiseUS from "./OurExpertiseUS";
import ContactUS from "./ContactUS";

function HomePageUS() {
  return (
    <div className="us_font">
      <HeroSectionUS />
      <ProgramsUS />
      <RoadMapUS />
      <AdvisorsUS />
      <PartUS />
      <JourneyUS />
      <OurJourneyUS />
      <OurExpertiseUS />
      <ContactUS />
    </div>
  );
}
export default HomePageUS;
