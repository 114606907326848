import React from "react";
import "../../Styles/RoadMapUS.css";
import roadmap_girl_img from "../../Assets/roadmap_girl_img.png";
function RoadMapUS() {
  return (
    <div className="container">
      <div id="us_roadmap_bg" className="row">
        <div className="col-md-8">
          <div id="us_roadmap_text">Roadmap</div>
          <div id="us_roadmap_subtext">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor
          </div>
          <div id="us_roadmap_list_bg">
            <div className="d-flex align-items-center justify-content-center flex-column" id="us_list_left_bg">
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div id="list_circle_outer">
                <div id="list_circle_inner"></div>
              </div>
              <div id="list_circle_line"></div>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div id="list_circle_outer">
                <div id="list_circle_inner"></div>
              </div>
              <div id="list_circle_line"></div>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div id="list_circle_outer">
                <div id="list_circle_inner"></div>
              </div>
              <div id="list_circle_line"></div>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div id="list_circle_outer">
                <div id="list_circle_inner"></div>
              </div>
              {/* <div id="list_circle_line"></div> */}
            </div>
            </div>
           

            <div id="us_list_right_bg">
              <div id="us_roadmap_heading" style={{marginTop:"0"}}>
                Degree
                <div id="us_roadmap_subheading">
                  Lorem ipsum dolor sit amet, consectetur
                </div>
              </div>
              <div id="us_roadmap_heading">
                Live Project & Upskilling
                <div id="us_roadmap_subheading">
                  Lorem ipsum dolor sit amet, consectetur
                </div>
              </div>
              <div id="us_roadmap_heading">
                Interview Preparation
                <div id="us_roadmap_subheading">
                  Lorem ipsum dolor sit amet, consectetur
                </div>
              </div>
              <div id="us_roadmap_heading">
                Job Placement
                <div id="us_roadmap_subheading">
                  Lorem ipsum dolor sit amet, consectetur
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div id="roadmap_circle_bg">
            <img src={roadmap_girl_img} width="100%" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default RoadMapUS;
