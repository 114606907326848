import React from "react";
import "../../Styles/PartUS.css";
import us_part_img1 from "../../Assets/us_part_img1.png";
import us_part_img2 from "../../Assets/us_part_img2.png";
import us_part_img3 from "../../Assets/us_part_img3.png";
import us_part_img4 from "../../Assets/us_part_img4.png";
import us_part_img5 from "../../Assets/us_part_img5.png";

function PartUS() {
  return (
    <div className="container">
      <div id="us_part_heading">Why To Be Part of NYINST</div>
      <div id="us_part_subheading">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor <br />
        incididunt ut labore et dolore magna aliqua. 
      </div>
      <div className="row" id="us_part_imgs">
        <div className="col-md-6">
          <div id="us_part_1">
            <img src={us_part_img1} alt="" width="100%" />
            <div id="us_latest_text">Latest Technologies</div>
            <div id="us_latest_subtext">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div id="us_part_2">
            <img src={us_part_img2} alt="" width="100%" />
            <div id="us_latest_text">Mentored By Top Experts</div>
            <div id="us_latest_subtext">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div id="us_part_3">
            <img src={us_part_img3} alt="" width="100%" />
            <div id="us_latest_text3">Industry-Aligned Curriculum</div>
            <div id="us_latest_subtext">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div id="us_part_3">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={us_part_img4}
                alt=""
                width="100%"
                style={{ width: "180px", height: "180px", marginTop:"70px" }}
                align="center"
              />
            </div>
            <div id="us_latest_text">Hands-On Learning</div>
            <div id="us_latest_subtext">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div id="us_part_3">
            <img src={us_part_img5} alt="" width="100%" style={{width:"287px", height:"246px"}}/>
            <div id="us_latest_text">Get Hired In Top Companies</div>
            <div id="us_latest_subtext">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartUS;
