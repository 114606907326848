import React from "react";
// import Header from "./Pages/Header";
// import HeroSection from "./Pages/HeroSection";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import CookiePolicy from "./Pages/CookiePolicy";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsCondition from "./Pages/Terms";
import ContactUs from "./Pages/ContactUs";
import Blogs from "./Pages/Blogs";
import "./App.css";
import EventsListSection from "./Pages/EventsComponents/EventDetail";
import SaEnggEvents from "./EventsDetails/SaEnggCollegeBlockChain";
import SkEnggEvents from "./EventsDetails/SkrEnggCollege";
import AmsCollgeEvent from "./EventsDetails/AmsMuthaputhupet";
import BethCollegeEvent from "./EventsDetails/BethlehemInstituteKarungal";
import DmiEnggEvent from "./EventsDetails/DmiEnggCollege";
import JosephEvent from "./EventsDetails/JosephSynergy2k19 ";
import MgrEvent from "./EventsDetails/NyinstDrMgrCollege";
import PanimalarEvent from "./EventsDetails/PanimalarInstituteWorkshop";
import AiBlogDetails from "./BlogDetails/AiBlogDetails";
import AutomationTesting from "./BlogDetails/AutomationTestingBlogDetails";
import BigDataBlogDetails from "./BlogDetails/BigDataBlogDetails";
import DataAnalyticsBlogDetails from "./BlogDetails/DataAnalyticsBlogDetails";
import DatabaseDesignBlogDetails from "./BlogDetails/DatabaseDesignBlogDetails";
import DataDrivenBlogDetails from "./BlogDetails/DataDrivenBlogDetails";
import FullStackBlogDetails from "./BlogDetails/FullStackBlogDetails";
import ImpressiveResumeBlogDetails from "./BlogDetails/ImpressiveResumeBlogDetail";
import InterviewTipsBlog from "./BlogDetails/InterviewTipsBlogDetails";
import MachineLearningBlogDetail from "./BlogDetails/MachineLearningBlogDetails";
import PythonBlogDetails from "./BlogDetails/PythonBlogDetails";
import SoftwareTestingBlogDetails from "./BlogDetails/SoftwareTestingBlogDetails";
import TechieHome from "./TechiePro/TechieHomepage";
import PageNotFound from "./Pages/PageNotFound";
import Construction from "./Pages/Construction";
import TechieSuperHome from "./TechieSuper/TechieSuperHomepage";
// import TechieNerdsHome from "./TechieNerds/TechieNerdsHomepage";
import Sticky from "./Pages/Sticky";
// import HireHero from "./HireFromUs/HireHeroSection";
import HireHomePage from "./HireFromUs/HireHomePage";
import ScholarshipHome from "./Scholarship/HomePage";
import InternHome from "./TechieIntern/InternHome";
import LandingHomePage from "./LandingPage/LandingHomePage";
import Responsive from "./LandingPage/Responsive";
import Sticky2 from "./LandingPage/ScrollExpertise";
import Certificate from "./Pages/Certificate";
import IAMS from "./Pages/IAMS";
import HomePageUS from "./NyinstUS/Components/HomePage/HomePageUS";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* nyinst IN */}
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/in" element={<Homepage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/ezine" element={<Blogs />} />
          <Route path="/events" element={<EventsListSection />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsCondition />} />

          {/* For Events */}
          <Route path="/sa-engg-events" element={<SaEnggEvents />} />
          <Route path="/skr-engg-events" element={<SkEnggEvents />} />
          <Route path="/ams-engg-events" element={<AmsCollgeEvent />} />
          <Route path="/beth-engg-events" element={<BethCollegeEvent />} />
          <Route path="/dmi-engg-events" element={<DmiEnggEvent />} />
          <Route path="/joseph-engg-events" element={<JosephEvent />} />
          <Route path="/mgr-engg-events" element={<MgrEvent />} />
          <Route path="/panimalar-engg-events" element={<PanimalarEvent />} />
          
          {/* For Blogs */}
          <Route path="/ai" element={<AiBlogDetails />} />
          <Route path="/automation-testing" element={<AutomationTesting />} />
          <Route path="/big-data" element={<BigDataBlogDetails />} />
          <Route path="/data-analytics" element={<DataAnalyticsBlogDetails />} />
          <Route path="/database-design" element={<DatabaseDesignBlogDetails />} />
          <Route path="/data-driven" element={<DataDrivenBlogDetails />} />
          <Route path="/full-stack" element={<FullStackBlogDetails />} />
          <Route path="/impressive-resume" element={<ImpressiveResumeBlogDetails />} />
          <Route path="/interview-tips" element={<InterviewTipsBlog />} />
          <Route path="/machine-learning" element={<MachineLearningBlogDetail />} />
          <Route path="/python-details" element={<PythonBlogDetails />} />
          <Route path="/software-testing" element={<SoftwareTestingBlogDetails />} />

          {/* Programs */}
          <Route path="/techie-pro" element={<TechieHome />} />
          <Route path="/techie-super" element={<TechieSuperHome />} />
          {/* <Route path="/techie-nerds" element={<TechieNerdsHome />} /> */}
          <Route path="/techie-intern" element={<InternHome />} />
          <Route path="/techie-iv" element={<Construction />} />
          <Route path="/techie-thon" element={<Construction />} />
         
          {/* sticky */}
          <Route path="/sticky" element={<Sticky />} />
          <Route path="/stickie" element={<Sticky2 />} />

          {/* Hire from us */}
          <Route path="/hire" element={<HireHomePage />} />

          {/* Scholarship */}
          <Route path="/scholarship" element={<ScholarshipHome />} />
          <Route path="/responsive" element={<Responsive />} />

          {/* Certificate */}
          <Route path="/certificate" element={<Certificate />} />
          <Route path="/IAMS" element={<IAMS />} />
          {/* Landing Page */}
          <Route path="/best-full-stack-development-training-institute-in-bangalore" element={<LandingHomePage />} />



          {/* nyinst US */}
          <Route path="/us" element={<HomePageUS />} />
          <Route path="/jp" element={<HomePageUS />} />
          <Route path="/ro" element={<HomePageUS />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
